@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    .content-scrollbar::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }
  
    .content-scrollbar::-webkit-scrollbar-thumb {
        background: rgba(#242424, .7);
        border-radius: 30px;
    }
  
    .content-scrollbar::-webkit-scrollbar-track {
        background: rgba(#fff, .2);
    }
  
    // For Internet Explorer
    .content-scrollbar {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
}

@include scrollbars(.4em, #222fb9);

.fileinput{
    position: relative;

    .inputfile-action-box{
        width: .2px;
        height: .2px;
        opacity: 0;
        position: absolute;
    }
}

.taginput-container{
    .badge{
        &.bg-secondary{
            background-color: #222fb9 !important;
            font-weight: normal !important;
            border: none;
            margin: 2px !important;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .container-list{
        display: flex;
        height: auto;
        background: #fff;
        //border: 5px solid #f3f3f3;
        border-bottom: 2px solid #1C2799;
        color: #6e6e6e;
        padding: 8px 0px;
        cursor: text;
        //border-radius: 1.25rem;

        transition: .4s ease all;

        &:hover{
            background-color: rgb(248, 248, 248);
        }

        .form-control{
            border: none;
            display: flex;
            min-height: auto !important;
            height: auto !important;
        }

        .input-tag-control{
            padding: 0px 8px;
            border: none;
            background: transparent;
        }
    }
}

.item-click{
    cursor: pointer !important;
}

.btn-unstyled{
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
}

.css-2613qy-menu{
    z-index: 5;
    opacity: 0;
}

.searchbar-mobile-container{
    .results-top{
        top: 85px !important;
        border-radius: 0px !important;

        .card{
            border-radius: 0px !important;
        }
    }
}

.search-results-container{
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 1;

    .card{
        border: 1px solid #f3f3f3;
        overflow: hidden;
    }

    &.results-top{
        top: 100px;
    }

    .search-results-container-data{
        height: auto;
        max-height: 200px;
        overflow-y: auto;
    }
}

.link-item-preview-list{
    display: flex;
    width: 100%;
    
    :hover{
        background-color: #f3f3f3;
    }
}

.dropleft .dropdown-toggle:before, 
.dropright .dropdown-toggle:before, 
.dropup .dropdown-toggle:after, 
.dropdown-toggle:after{
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f107";
}

.dropdown-toggle{
    cursor: pointer !important;
}

.input-search-multiple{
    background: #fff;
    border-bottom: 2px solid #1C2799;
    //border-color: #757fe6 #f3f3f3;
    color: #6e6e6e;
    height: 56px;
    padding: 0.5rem 1.5rem;
    //border-radius: 1.25rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    cursor: text;

    transition: .4s ease all;

    &:hover{
        background-color: rgb(248, 248, 248);
    }

    input{
        padding: 0 !important;
        margin: 0 !important;
        padding-left: 15px !important;
        display: inline-flex;
        line-height: 10px;
        border: none !important;
        height: auto;
        background-color: transparent;
    }

    .item-in-multiple{
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 1.25rem;
        background-color: #1C2799;
        color: #fff;
        padding: 0.1rem 0.6rem;
        margin: 4px 5px;
        cursor: pointer !important;

        &:first-of-type{
            margin-left: 0px;
        }
    }
}

.content-results-multiple{
    background-color: #fff;
    border-radius: 1.25rem;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 12px;
    box-shadow: 0px 3px 3px rgba(#909090, .2);
    
    z-index: 1000000;  

    .listdata{
        height: auto;
        max-height: 200px;
        overflow-y: auto;
    }
}

.item-multisearch-list{
    transition: .4s ease all;

    &:hover{
        background-color: #1C2799;
        color: #fff;
    }
}

.react-dropdown__indicator{
    display: block !important;
}

.react-dropdown__indicator-separator{
    display: none !important;;
}

.react-dropdown__value-container{
    text-align: center !important;
}

.react-dropdown__control{
    width: 120px;
    cursor: pointer !important;
    background-color: #222fb9 !important;
    border: none !important;
}

.react-dropdown__placeholder{
    color: #fff !important;
    font-size: 0.813rem;
}

.react-dropdown__single-value{
    color: #fff !important;
    font-size: 0.813rem;
}

.taginput-container{
    position: relative;

    .tag-content-result{
        left: 0;
        top: 110%;
        position: absolute;
        width: 100%;
        box-shadow: 0px 3px 3px rgba(#909090, .2);
        z-index: 1000;
        border-radius: 1.75rem;
        overflow: hidden;

        li{
            cursor: pointer !important;
            display: flex;
            font-size: 1em;
            //background-color: #222fb9 !important;

            &:hover{
                background-color: #222fb9 !important;
                color: #fff;
            }
        }
    }
}

@media (min-width: 1200px) {
    .content-scrollbar{
        overflow-y: auto !important;
        max-height: 100%;
    }

    .menu-toggle{
        .content-scrollbar{
            overflow-y: initial !important;
            max-height: auto;
        }
    }
}

@media (max-width: 768px) {
    .content-scrollbar{
        overflow-y: auto !important;
        max-height: 100%;
    }
}

.popover-primary{
    border-color: #1C2799;

    .popover-header{
        background-color: #1C2799;
    }

    .arrow::after, 
    .bs-popover-auto[x-placement^="bottom"] .arrow::after, 
    .bs-popover-auto[x-placement^="bottom"] .arrow::after {
        border-bottom-color: #1C2799;
    }
}

.search_bar{

    input{
        transition: all .4s ease;
        min-width: 250px !important;
    }

    &.is-hover{
        input{
            min-width: 400px !important;
        }
    }
}

@media (max-width: 1200px) {
    .brand-logo{
        .logo-abbr{
            display: block !important;
        }
    }
}

@media (min-width: 1200px) {
    .brand-logo{
        .logo-abbr{
            display: none !important;
        }
    }
}

@media (min-width: 1200px) {
    .content-name-file{
        max-width: 330px;

        .content-file-title{
            display: inline-flex;
            flex-wrap: wrap;

            .title-wrap{
                display: inline-flex;
                margin-right: 5px;

                &.letter-wrap{
                    margin-right: 0px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .nav-tabs{
        .nav-item{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-right: 0px;

            .nav-link{
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-right: 0px;
            }
        }
    }
}

@media (max-width: 992px) {
    body.show-search-mobile{
        .header{
            z-index: 6 !important;
        }

        .searchbar-mobile-container{
            top: 0;
            opacity: 1;
            z-index: 100;
        }
    }
}

.header{
    z-index: 4 !important;
}

.searchbar-mobile-container{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #fff;
    //z-index: 100000000 !important;

    transition: all 0s ease;

    .searchbar-mobile{
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;

        background-color: red;

        input{
            height: 100%;
            //width: 80%;
            display: flex;
            border-radius: 0px;
            //padding: 0;
            margin: 0;
        }

        button{
            border-radius: 0;
        }
    }
}

.is-symbolic-item{
    position: relative;

    &::after{
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900; 
        content: "\f064";
        color: #fff;

        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        z-index: 3;

        background-color: #2781d5;

        bottom: 0;
        left: 0;
    }
}

.hyiOnG{
    max-width: initial !important;
}

.table-overflow{
    overflow-y:initial !important;
    height: auto;
    position: static;
}

.input-file-action{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.file-sending-banner{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.8);
}

.download-files-list{
    position: fixed;
    height: 60px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: rgba(#eee, 1);
    z-index: 100;
    border-top: 2px solid rgba(#666, .4);;

    li{
        position: relative;
        display: inline-flex;
        padding: 10px 15px;
        height: 100%;
        align-items: center;
        justify-content: center;

        &::after{
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(#666, .4);
            height: 50%;
            width: 2px;
            margin: auto;
        }

        .progress-bar-file{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background: rgba(#666, .5);

            .progress-data{
                position: absolute;
                top: 0;
                left: 0;
                height: 6px;

                background: #222FB9;
                transition: all .5s ease;
            }
        }
    }
}

.dropdown-item.notactive.active, .dropdown-item.notactive:active{
    background-color: transparent !important;
}

.table-responsive{

    &.isDropdownOpen{
        overflow-x: visible;

        //old

        //padding-top: 250px; 
        //margin-top: -250px;

        //padding-bottom: 250px; 
        //margin-bottom: -250px;


        // transform: translateY(-250px);
    }
}

table.table{
    //background-color: red;
    //overflow-x: visible;

    //overflow-y: visible;
    //overflow-x: visible;

    //overflow-x: hidden  !important;
    //overflow-y: visible !important;
}

.table-responsive .dropdown-menu{
    z-index: 100 !important;
}

.notification .title{
    color: #fff;
}

/*
.dataTables_wrapper{
    overflow-x: visible;
    overflow-y: auto;
}
*/

body{
    //overflow: hidden;
}

#container-app{
    overflow-y: hidden !important;
    overflow-x: hidden;

    //background: red;
}


.widget-stat {
    .card-body{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .media {
        // display: block;
        width: 100%;
        flex-wrap: nowrap;

        & > span {
            height: 85px;
            width: 85px;
            border-radius: 50px;
            padding: 10px 12px;
            font-size: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #464a53;

            @media screen and (min-width: 1200px) {
                height: 70px !important;
                width:  70px !important;
                overflow: hidden;
            }

            @media screen and (min-width: 1400px) {
                height: 85px !important;
                width:  85px !important;
                overflow: hidden;
            }
        }
    }
}


table{
    tbody{
        tr{
            &.selected{
                background-color: #dbeaf8;

                &:hover, &:active{
                    background-color: #dbeaf8;
                }
            }
        }
    }

    .table-checkbox{
        display: flex;
        align-items: center;

        input[type="checkbox"]{
            width: 20px;
            height: 20px;
            cursor: pointer;

            background-color: #909090;

            &::after{
                display: none;
            }
        }
    }
}


.pdf-container{
    // display: none;
    background-color: #FBFBFB;
    max-height: calc(100vh - 220px);
    overflow: auto;

    @media screen and (max-width: 992px){
        // max-height: 400px;
    }

    .canvas {
        width: 100% !important;
        height: auto !important;
    }

    .pdf-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btns{
        button{
            background-color: transparent;
            cursor: pointer;
            border: 0px;

            color: #222FB9;
            padding: 0px 1em;
        }
    }
}

.modal-pdf{
    @media screen and (min-width: 768px){
        max-width: 90%;
        margin: 0px auto;
        margin-top: 10px;
    }

    .modal-content{
        // min-height: 100vh;
        // max-height: 100vh;
    }
}

.modal.show{
    .modal-dialog{
        &.modal-pdf{
            // margin: 0px auto !important;

            @media screen and (min-width: 768px){
                max-width: 75%;

                /*
                    margin: 0px auto;

                    .modal-content{
                        min-height: 100vh;
                        max-height: 100vh;
                    }
                */
            }

            @media screen and (max-width: 992px){

                .modal-content{
                    min-height: 100vh;
                    max-height: 100vh;
                }
            }
        }
    }
}